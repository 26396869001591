<template>
  <v-app>
    <loading
      :active.sync="$store.state.pageLoading"
      :is-full-page="true"
      color="#007BFF"
      :width="128"
      :height="128"
      :opacity="0.9"
    ></loading>
    <nav-drawer />
    <navbar />
    <router-view />
    <Dialog />
    <Footer />
  </v-app>
</template>

<script>
import Navbar from "./views/layouts/Navbar.vue";
import NavDrawer from "./views/layouts/NavDrawer.vue";
import Footer from "./views/layouts/Footer.vue";
import Dialog from "./views/layouts/Dialog.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "App",
  components: {
    Navbar,
    NavDrawer,
    Footer,
    Dialog,
    Loading,
  },
  watch: {
    $route(to) {
      // console.log(to, from);
      if (to.name !== "contact") {
        this.$store.dispatch("sectionFooter", true);
      }
    },
  },
  mounted() {
    // load menu reference link

    this.$axios
      .get(`https://research.tndr-tdpf.info/api/fetch/other-link`)
      .then((res) => {
        const data = res.data;
        // console.log(data);
        this.$store.dispatch("menuReferenceLinkUpdate", [
          { title: data[8].link_title, link: data[8].reference_link },
          { title: data[7].link_title, link: data[7].reference_link },
          { title: data[6].link_title, link: data[6].reference_link },
          { title: data[5].link_title, link: data[5].reference_link },
          { title: data[4].link_title, link: data[4].reference_link },
          { title: data[3].link_title, link: data[3].reference_link },
          { title: data[2].link_title, link: data[2].reference_link },
          { title: data[1].link_title, link: data[1].reference_link },
          { title: data[0].link_title, link: data[0].reference_link },
        ]);
      });
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;600&display=swap");
$body-font-family: "Kanit", sans-serif !default;

$primary-theme: #ab212c;
$secondary-theme: #d98100;
$dark-theme: #2b3a4a;
$blue-darken-theme: #2d4b80;
$blue-lighten-theme: #5eb4dd;

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
small,
div {
  font-family: "Kanit", sans-serif !important;
}

.bg-primary-red {
  background-color: $primary-theme !important;
}

.bg-primary {
  background-color: $blue-darken-theme !important;
}

.bg-secondary {
  background-color: $secondary-theme !important;
}

.bg-blue-lighten {
  background-color: $blue-lighten-theme !important;
}

.bg-blue-darken {
  background-color: $blue-darken-theme !important;
}

.text-primary,
.text-primary-blue {
  color: $blue-darken-theme !important;
}

.text-secondary {
  color: $secondary-theme !important;
}

.text-primary-red {
  color: $primary-theme !important;
}

.hover:hover {
  div,
  a,
  p,
  small,
  label {
    background-color: $secondary-theme !important;
    color: #ffffff !important;
  }

  background-color: $secondary-theme !important;
  color: #ffffff !important;
}

div {
  border-radius: 0 !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.menu-button {
  font-size: 16px !important;
}

.border-none {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.v-carousel__item {
  height: auto !important;
}

.full-screen {
  min-height: 100vh;
}

.text-nowrap {
  white-space: nowrap !important;
}

.img-fluid {
  width: 100%;
}

.note-float-left,
.note-float-right {
  width: auto !important;
}

.v-card--reveal {
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}

.v-application--wrap {
  min-height: 100vh;
  justify-content: center;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
