<template>
  <div>
    <banner />
    <div class="c-main-content pt-10 pb-10">
      <v-container max-wdith="1200px">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="4"
            v-for="(item, index) in items"
            :key="index"
          >
            <v-list-item class="mb-2">
              <v-list-item-content>
                <v-img
                  class="mb-4 cursor-pointer"
                  width="368"
                  height="268"
                  :src="item.thumbnail"
                  @click="$router.push({ path: `/post/${item.id}` })"
                  cover
                ></v-img>
                <v-list-item-title
                  class="text-primary font-weight-bold"
                  style="cursor: pointer; font-size: 20px"
                  @click="$router.push({ path: `/post/${item.id}` })"
                  v-text="item.title"
                >
                </v-list-item-title>

                <v-list-item-subtitle class="d-flex align-center pt-2">
                  <v-icon small class="mr-2">
                    mdi-clock
                  </v-icon>
                  <span v-text="item.date_thai"></span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Banner from "../../layouts/Banner";

export default {
  name: "Post",
  components: {
    Banner,
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.$store.dispatch("pageLoading", true);
  },
  mounted() {
    this.$store.dispatch("bannerText", "หนังสือและบทความ");
    this.$axios
      .get(`https://research.tndr-tdpf.info/api/fetch/post`)
      .then((res) => {
        const data = res.data;
        let newData = [];

        data.forEach((item) => {
          item.title = item.post_title;
          item.thumbnail = item.post_thumbnail;
          item.detail = item.post_detail;
          delete item.post_title;
          delete item.post_thumbnail;
          delete item.post_detail;

          newData.push(item);
        });

        this.items = newData;
        this.$store.dispatch("pageLoading", false);
      });
  },
};
</script>

<style scoped>
.c-main-content {
  background-color: #f5f5f5;
  min-height: 600px;
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 1200px;
  }
}
</style>
