import Post from "../views/pages/knowledge/Post.vue";
import PostDetail from "../views/pages/knowledge/PostDetail.vue";
import ResultConference from "../views/pages/knowledge/ResultConference.vue";
import ResultConferenceDetail from "../views/pages/knowledge/ResultConferenceDetail.vue";
import DataLinkSystem from "../views/pages/knowledge/DataLinkSystem.vue";
import CourseAcademic from "../views/pages/knowledge/CourseAcademic.vue";
import ExpertInventory from "../views/pages/knowledge/expert/Expert.vue";
import ExpertDetail from "../views/pages/knowledge/expert/ExpertDetail.vue";
import ResearchInventory from "../views/pages/knowledge/publication/Publication.vue";
import PublicationDetail from "../views/pages/knowledge/publication/PublicationDetail.vue";

export default [
  {
    // หนังสือและบทความ
    path: "/post",
    name: "post",
    component: Post,
  },
  {
    // รายละเอียด หนังสือและบทความ
    path: "/post/:id",
    name: "post-detail",
    component: PostDetail,
  },
  {
    // ผลการประชุมและสัมนาวิชาการ
    path: "/result-conference-seminar",
    name: "result-conference-seminar",
    component: ResultConference,
  },
  {
    // รายละเอียด ผลการประชุมและสัมนาวิชาการ
    path: "/result-conference-seminar/:id",
    name: "result-conference-seminar-detail",
    component: ResultConferenceDetail,
  },
  {
    // ระบบเชื่อมโยงฐานข้อมูลภัยพิบัติ
    path: "/data-link-system",
    name: "data-link-system",
    component: DataLinkSystem,
  },
  {
    // หลักสูตรการเรียนการสอน
    path: "/course-academic",
    name: "course-academic",
    component: CourseAcademic,
  },
  {
    path: "/expert-inventory",
    name: "expert-inventory",
    component: ExpertInventory,
  },
  {
    path: "/expert-inventory/:data",
    name: "expert-inventory-detail",
    component: ExpertDetail,
  },
  {
    path: "/research-inventory",
    name: "research-inventory",
    component: ResearchInventory,
  },
  {
    path: "/publication/:id",
    name: "publication-detail",
    component: PublicationDetail,
  },
];
