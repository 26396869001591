<template>
  <v-navigation-drawer v-model="drawer" app persistent disable-resize-watcher>
    <v-list-item style="background-color: #B11116" class="pl-0 ml-0">
      <v-list-item-content class="justify-center">
        <v-img
          src="@/assets/header-logo.png"
          class="header-logo header-height"
          contain
        ></v-img>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list>
      <v-list-item>
        <v-list-item-icon style="cursor: pointer">
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title @click="redirectHomePage()" style="cursor: pointer"
          >หน้าแรก</v-list-item-title
        >
        <v-list-item-icon style="cursor: pointer" @click="closeNavDrawer">
          <v-icon>mdi-menu</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-group
        v-for="(item, menuIndex) in menu"
        :key="`menu-${menuIndex}`"
        :value="false"
        :prepend-icon="item.icon"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </template>
        <v-list-item
          v-for="(subMenu, index) in item.subMenu"
          :key="`submenu-${index}`"
        >
          <v-list-item-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <a :href="subMenu.link" v-if="menuIndex !== 5">{{
                subMenu.title
              }}</a>
              <a :href="subMenu.link" v-else target="_blank">{{
                subMenu.title
              }}</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    drawer: false,
    menu: [],
    items: [
      { title: "Dashboard", icon: "mdi-view-dashboard" },
      { title: "Photos", icon: "mdi-image" },
      { title: "About", icon: "mdi-help-box" },
    ],
    right: null,
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
  }),
  computed: {
    ...mapState(["navDrawer"]),
  },
  methods: {
    closeNavDrawer() {
      this.$store.dispatch("navDrawer", !this.$store.state.navDrawer);
    },
    redirectHomePage() {
      window.location.href = "/";
    },
  },
  mounted() {
    this.drawer = this.$store.state.navDrawer;
    this.menu = this.$store.state.menu;
  },
  watch: {
    navDrawer(newValue) {
      this.drawer = newValue;
    },
  },
};
</script>

<style scoped>
/* iPhone 6/7/8 */
@media only screen and (max-width: 375px) {
  .header-text {
    font-size: 14px;
    padding-left: 40px;
  }

  .header-logo {
    height: 160px;
    margin-left: 20px;
  }

  .header-height {
    height: 100px;
  }
}

/* iPhone 6/7/8 Plus */
@media only screen and (min-width: 412px) {
  .header-text {
    font-size: 14px;
    padding-left: 40px;
  }

  .header-logo {
    height: 160px;
    /* margin-left: 20px; */
  }

  .header-height {
    height: 100px;
  }
}

/* iPad 11 inch */
@media only screen and (min-width: 674px) {
  .header-text {
    font-size: 20px;
    padding-left: 40px;
  }

  .header-logo {
    height: 160px;
    /* margin-left: 20px; */
  }

  .header-height {
    height: 160px;
  }
}

/* iPad */
@media only screen and (min-width: 768px) {
  .header-text {
    font-size: 24px;
    padding-left: 40px;
  }

  .header-logo {
    height: 160px;
    /* margin-left: 20px; */
  }

  .header-height {
    height: 160px;
  }
}

/* iPad Pro */
@media only screen and (min-width: 1024px) {
  .header-text {
    font-size: 24px;
    padding-left: 40px;
  }

  .header-logo {
    height: 160px;
  }

  .header-height {
    height: 160px;
  }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  .header-text {
    font-size: 28px;
    padding-left: 40px;
  }

  .header-logo {
    height: 160px;
  }

  .header-height {
    height: 160px;
  }
}
</style>
