<template>
  <v-container class="section-disaster-expert-inventory py-16" fluid>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-img
          :src="publication.publication_thumbnail"
          width="100%"
          height="400"
          cover
        ></v-img>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="px-10">
        <div style="//border: 1px solid red;">
          <h3
            style="font-size: 24px"
            v-text="publication.publication_name"
          ></h3>
          <p class="pt-2" v-html="publication.publication_description"></p>
        </div>
      </v-col>
      <v-col class="text-right" cols="12">
        <v-btn
          class="bg-primary-red white--text"
          @click="handlePurposeDialog(publication.id)"
        >
          Download
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PublicationInventoryDetail",
  data() {
    return {
      items1: [
        "ภาคเหนือ",
        "ภาคตะวันออกเฉียงเหนือ",
        "ภาคตะวันตก",
        "ภาคกลาง",
        "ภาคตะวันออก",
        "ภาคใต้",
      ],
      items2: ["จุฬาลงกรณ์มหาวิทยาลัย"],
      items3: ["ธรณีวิทยา"],
      purposeDialog: false,
      publication: {},
    };
  },
  methods: {
    onClickDisasterExpertInventory() {
      this.purposeDialog = !this.purposeDialog;
    },
    handlePurposeDialog(dataId) {
      this.$store.dispatch("purposeDialog", {
        type: "publication",
        open: true,
        userExpertId: 0,
        publicationId: dataId,
      });
    },
  },
  created() {
    this.$store.dispatch("pageLoading", true);
  },
  mounted() {
    console.log(`>>> publication detail mounted`);
    const dataId = this.$route.params.id;
    const domain = this.$store.state.apiDomain;

    this.$axios
      .get(`${domain}/api/fetch/publication/show/${dataId}`)
      .then((res) => {
        const data = res.data;

        this.publication = data;
        this.$store.dispatch("pageLoading", false);
      });
  },
};
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .container {
    max-width: 1200px;
  }
}
</style>
