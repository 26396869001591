<template>
  <div>
    <banner />
    <div class="c-main-content pt-10 pb-10">
      <v-container max-wdith="1200px">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            :md="$store.state.layouts.articleLayout.fullPage ? 12 : 9"
          >
            <v-card class="mx-auto pa-10 mb-6">
              <static-article-header />
              <div v-if="$store.state.layouts.articleHeaderStyle === 'clean'">
                <h5
                  class="text-h5 font-weight-bold"
                  v-text="$store.state.pageTitle"
                ></h5>
                <br />
                <div v-html="$store.state.pageContent"></div>
              </div>
              <div v-else class="pt-8">
                รายละเอียด ...
              </div>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="3"
            v-if="!$store.state.layouts.articleLayout.fullPage"
          >
            <v-card class="mx-auto" tile>
              <v-list-item class="bg-primary mb-4">
                <v-list-item-content>
                  <v-list-item-title class="d-flex align-center white--text">
                    <v-icon class="white--text mr-2" small>
                      mdi-book-open-page-variant
                    </v-icon>
                    ข่าวสารอื่นๆ
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                two-line
                v-for="(item, index) in Array(5).keys()"
                :key="index"
                class="mb-2"
              >
                <v-list-item-content>
                  <v-img
                    class="mb-4"
                    width="100%"
                    src="https://picsum.photos/500/300?image=10"
                    cover
                  ></v-img>
                  <v-list-item-title
                    class="text-primary font-weight-bold"
                    style="cursor: pointer"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.</v-list-item-title
                  >

                  <v-list-item-subtitle class="d-flex align-center pt-2">
                    <v-icon small class="mr-2">
                      mdi-clock
                    </v-icon>
                    <span>21 ตุลาคม 2563</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <div class="text-right">
                    <v-btn
                      class="bg-primary-red white--text"
                      style="width: 100%"
                      tile
                    >
                      ดูรายการทั้งหมด
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Banner from "./Banner";
import StaticArticleHeader from "./components/StaticArticleHeader";

export default {
  components: {
    Banner,
    StaticArticleHeader,
  },
  created() {
    this.$store.dispatch("pageLoading", true);
  },
  mounted() {
    console.log(`>>> static page mounted`);
    console.log(`>>> current page`, this.$store.state.currentPage);
    const dataId = this.$route.params.id;
    const domain = this.$store.state.apiDomain;

    if (dataId !== undefined) {
      // const name = this.$route.name.split("-")[0];
      const name = this.$store.state.currentPage;

      if (name === "news") {
        this.$store.dispatch("bannerText", "ข่าวประชาสัมพันธ์");
        // fetch detail
        this.$axios
          .get(`${domain}/api/fetch/news/show/${dataId}`)
          .then((res) => {
            const data = res.data;

            console.log(data);
            this.$store
              .dispatch("pageContentWithTitle", {
                pageTitle: data.news_title,
                pageContent: data.news_detail,
              })
              .then(() => {
                this.$store.dispatch("pageLoading", false);
              });
          });
      }

      if (name === "conference-seminar") {
        this.$store.dispatch("bannerText", "งานประชุมและสัมมนาวิชาการ");
        // fetch detail
        this.$axios
          .get(`${domain}/api/fetch/conference-seminar/show/${dataId}`)
          .then((res) => {
            const data = res.data;

            console.log(data);
            this.$store
              .dispatch("pageContentWithTitle", {
                pageTitle: data.conference_title,
                pageContent: data.conference_detail,
              })
              .then(() => {
                this.$store.dispatch("pageLoading", false);
              });
          });
      }
    }

    // console.log(`>>> load content id`, this.$route.params.id);
  },
};
</script>

<style scoped>
.c-main-content {
  background-color: #f5f5f5;
  min-height: 600px;
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 1200px;
  }
}
</style>
