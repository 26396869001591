<template>
  <div>
    <carousel :items="slides" />

    <v-container class="pa-0" fluid>
      <v-row>
        <!-- first section -->
        <v-col cols="12" style="background-color: #FFF">
          <div class="d-flex justify-center" style="padding: 60px">
            <div>
              <h3
                class="text-primary-blue"
                style="font-size: 32px; text-align: center"
              >
                เครือข่ายพัฒนาความเข้มแข็งต่อภัยพิบัติไทย
              </h3>
              <br />
              <h4
                class="text-primary-blue"
                style="font-size: 28px; text-align: center"
              >
                Thai Network for Disaster Resilience<br />
                โครงการภายใต้การดำเนินงานของมูลนิธิเตรียมความพร้อมป้องกันภัยพิบัติในประเทศไทย
              </h4>

              <div style="padding: 60px 0 60px 0">
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="3" xl="3" class="pa-0">
                    <v-card
                      class="mx-auto"
                      style="padding: 40px 20px 40px 20px"
                    >
                      <div
                        class="d-flex flex-column justify-center align-center text-center"
                      >
                        <v-btn class="mx-2" fab dark large color="cyan">
                          <!-- <v-icon dark>
                            mdi-pencil-circle
                          </v-icon> -->
                          <v-img
                            src="@/assets/b1-01.png"
                            width="64"
                            height="64"
                          ></v-img>
                        </v-btn>
                        <v-card-title>
                          <p class="text-primary-blue text-nowrap">
                            ข้อมูลผู้เชี่ยวชาญ
                          </p>
                        </v-card-title>
                        <v-card-subtitle>
                          <div style="height: 80px">
                            <p>
                              แหล่งรวบรวมข้อมูลบุคลากรด้านภัยพิบัติทั้งนักวิชาการและระดับผู้ปฏิบัติ
                            </p>
                          </div>
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-btn
                            @click="$router.push({ name: 'expert-inventory' })"
                            class="bg-primary-red white--text"
                            depressed
                            style="width: 150px"
                          >
                            ดูเพิ่มเติม
                          </v-btn>
                        </v-card-actions>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" xl="3" class="pa-0">
                    <v-card
                      class="mx-auto"
                      style="padding: 40px 20px 40px 20px"
                    >
                      <div
                        class="d-flex flex-column justify-center align-center text-center"
                      >
                        <v-btn class="mx-2" fab dark large color="cyan">
                          <!-- <v-icon dark>
                            mdi-pencil-circle
                          </v-icon> -->
                          <v-img
                            src="@/assets/b2-01.png"
                            width="64"
                            height="64"
                          ></v-img>
                        </v-btn>
                        <v-card-title>
                          <p class="text-primary-blue text-nowrap">
                            ข้อมูลงานวิจัย
                          </p>
                        </v-card-title>
                        <v-card-subtitle>
                          <div style="height: 80px">
                            <p>
                              แหล่งรวบรวม
                              และแนะนำงานวิจัยที่เป็นของบุคลากรในเครือข่าย
                            </p>
                          </div>
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-btn
                            class="bg-primary-red white--text"
                            depressed
                            style="width: 150px"
                            @click="
                              $router.push({ name: 'research-inventory' })
                            "
                          >
                            ดูเพิ่มเติม
                          </v-btn>
                        </v-card-actions>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" xl="3" class="pa-0">
                    <v-card
                      class="mx-auto"
                      style="padding: 40px 20px 40px 20px"
                    >
                      <div
                        class="d-flex flex-column justify-center align-center text-center"
                      >
                        <v-btn class="mx-2" fab dark large color="cyan">
                          <!-- <v-icon dark>
                            mdi-pencil-circle
                          </v-icon> -->
                          <v-img
                            src="@/assets/b3-01.png"
                            width="64"
                            height="64"
                          ></v-img>
                        </v-btn>
                        <v-card-title>
                          <p class="text-primary-blue text-nowrap">
                            หนังสือและบทความ
                          </p>
                        </v-card-title>
                        <v-card-subtitle>
                          <div style="height: 80px">
                            <p>
                              แหล่งรวบรวมข้อมูลทั้งหนังสือ
                              และบทความด้านภัยพิบัติ
                            </p>
                          </div>
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-btn
                            class="bg-primary-red white--text"
                            depressed
                            style="width: 150px"
                            @click="$router.push({ name: 'post' })"
                          >
                            ดูเพิ่มเติม
                          </v-btn>
                        </v-card-actions>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" xl="3" class="pa-0">
                    <v-card
                      class="mx-auto"
                      style="padding: 40px 20px 40px 20px"
                    >
                      <div
                        class="d-flex flex-column justify-center align-center text-center"
                      >
                        <v-btn class="mx-2" fab dark large color="cyan">
                          <!-- <v-icon dark>
                            mdi-pencil-circle
                          </v-icon> -->
                          <v-img
                            src="@/assets/b4-01.png"
                            width="64"
                            height="64"
                          ></v-img>
                        </v-btn>
                        <v-card-title>
                          <p class="text-primary-blue text-nowrap">
                            การประชุมและสัมนาวิชาการ
                          </p>
                        </v-card-title>
                        <v-card-subtitle>
                          <div style="height: 80px">
                            <p>
                              สรุป และรวบรวมเนื้อหาหลังงานประชุม
                              และกิจกรรมทางวิชาการต่างๆ
                            </p>
                          </div>
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-btn
                            class="bg-primary-red white--text"
                            depressed
                            style="width: 150px"
                            @click="
                              $router.push({ name: 'conference-seminar' })
                            "
                          >
                            ดูเพิ่มเติม
                          </v-btn>
                        </v-card-actions>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
        <!-- second section -->

        <!-- third section -->
        <v-col cols="12" style="background-color: #F5F5F5">
          <v-container style="max-width: 1200px">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <h3
                  class="text-primary-blue"
                  style="font-size: 32px; text-align: center"
                >
                  ข่าวประชาสัมพันธ์
                </h3>
                <br />
                <h4
                  class="text-primary-blue"
                  style="font-size: 28px; text-align: center"
                >
                  สามารถติดตามมข่าวสารและกิจกรรมต่าง ๆ
                  ของเครือข่ายพัฒนาความเข้มแข็งต่อภัยพิบัติไทยได้ที่นี่
                </h4>
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div style="padding: 60px 0 40px 0">
                  <v-tabs
                    v-model="tab"
                    align-with-title
                    background-color="#2d4b80"
                  >
                    <v-tabs-slider color="#d98100"></v-tabs-slider>
                    <v-tab
                      v-for="(item, index) in tabItems"
                      :key="index"
                      class="white--text"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>

                  <!-- <v-tabs-items v-model="tab">
                    <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">
                      <v-card flat>
                        <v-card-text>{{ text }}</v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items> -->

                  <v-tabs-items v-model="tab">
                    <div style="padding: 40px 0 40px 0">
                      <v-row>
                        <v-col
                          v-for="(item, index) in newsItems[tab]"
                          :key="`tab-col-${index}`"
                          cols="12"
                          sm="12"
                          md="6"
                          lg="4"
                          xl="4"
                          class="d-flex flex-column"
                        >
                          <v-card class="flex-grow-1">
                            <v-img
                              v-if="item.thumbnail !== undefined"
                              :src="item.thumbnail"
                              width="100%"
                              height="268"
                              cover
                            ></v-img>
                            <v-card-title
                              class="d-flex justify-start align-start"
                              style="height: 100px"
                            >
                              <h2
                                class="text-h6 font-weight-bold"
                                v-text="item.title"
                              ></h2>
                            </v-card-title>
                            <v-card-subtitle class="pt-2">
                              <p class="green--text text--darken-3 subtitle-1">
                                <v-icon style="vertical-align: middle"
                                  >mdi-clock-outline</v-icon
                                >
                                <span
                                  class="pl-2"
                                  v-text="item.date_thai"
                                ></span>
                              </p>
                            </v-card-subtitle>
                            <v-card-actions
                              class="d-flex justify-center align-center pt-0 pb-6"
                            >
                              <v-btn
                                class="bg-primary-red white--text"
                                depressed
                                style="width: 150px"
                                @click="handleReadMore(item.id)"
                              >
                                ดูเพิ่มเติม
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tabs-items>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <!-- fourth section -->
        <v-col cols="12" style="padding-bottom: 0 !important" class="pa-0">
          <div
            style="padding: 60px; background-image: url('https://research.tndr-tdpf.info/images/overlay-01.jpg'); background-position: center center; background-size: cover; background-repeat: no-repeat"
          >
            <div>
              <h3
                class="white--text"
                style="font-size: 32px; text-align: center"
              >
                ภาพกิจกรรม
              </h3>

              <div style="padding: 20px 0 20px 0">
                <v-row>
                  <v-col
                    cols="12"
                    lg="4"
                    xl="3"
                    v-for="(item, index) in activityPictureMini"
                    :key="index"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <v-card class="mx-auto" color="grey lighten-4">
                        <v-img
                          :src="item.thumbnail"
                          width="100%"
                          height="320"
                          cover
                        >
                          <v-expand-transition>
                            <div
                              v-if="hover"
                              class="d-flex flex-column align-center justify-center transition-fast-in-fast-out bg-primary darken-2 v-card--reveal display-3 white--text text-center"
                              style="height: 100%;"
                            >
                              <span style="font-size: 18px; line-height: 40px">
                                {{ item.title }}
                                <br />
                                <span v-text="item.date_thai"></span>
                              </span>
                              <v-btn
                                class="bg-primary-red white--text mt-4"
                                @click="
                                  $router.push({ path: `/gallery/${item.id}` })
                                "
                                >ดูเพิ่มเติม</v-btn
                              >
                            </div>
                          </v-expand-transition>
                        </v-img>
                        <!-- <v-card-text class="pt-6" style="position: relative;">
                        <v-btn absolute color="orange" class="white--text" fab large right top>
                          <v-icon>add</v-icon>
                        </v-btn>
                        <div class="font-weight-light grey--text title mb-2">For the perfect meal</div>
                        <h3 class="display-1 font-weight-light orange--text mb-2">QW cooking utensils</h3>
                        <div class="font-weight-light title mb-2">
                          Our Vintage kitchen utensils delight any chef.<br />
                          Made of bamboo by hand
                        </div>
                      </v-card-text> -->
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" style="background-color: #f2f2f2">
          <div style="padding: 60px">
            <div>
              <v-row justify="center">
                <v-col
                  cols="12"
                  style="//border:1px solid red"
                  class="text-center"
                >
                  <h3 style="font-size: 32px" class="text-primary-blue">
                    เครือข่ายพัฒนาความเข้มแข็งต่อภัยพิบัติไทย (TNDR)
                  </h3>
                </v-col>
                <v-col cols="12" class="text-center">
                  <div class="embed-container">
                    <iframe
                      src="https://www.youtube.com/embed/C5mPOaAGbgo"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>

        <v-col cols="12" style="background-color: #FFF">
          <div style="padding: 60px">
            <v-carousel v-model="model" style="height: 238px">
              <v-carousel-item
                v-for="(item, index) in otherLinks"
                :key="index"
                @click="openReferenceLink(item.reference_link)"
                style="cursor: pointer"
              >
                <v-sheet
                  color="secondary"
                  height="250"
                  :style="{
                    backgroundImage: `url(${linkImages[index]})`,
                    backgroundRepeat: `no-repeat`,
                    backgroundSize: `cover`,
                    backgroundPosition: `center`,
                  }"
                  tile
                >
                  <v-row class="fill-height" align="center" justify="center">
                    <div class="display-2">
                      <a
                        :href="item.reference_link"
                        target="_blank"
                        class="text-primary-blue"
                      ></a>
                    </div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Carousel from "../../layouts/Carousel";

export default {
  name: "Home",
  components: {
    Carousel,
  },
  data: () => ({
    tab: null,
    activityPictureMini: [],
    newsItems: [],
    otherLinks: [],
    tabItems: [
      "ข่าวประชาสัมพันธ์",
      "ภาพกิจกรรม",
      "การประชุมและสัมมนาวิชาการ",
      "สรุปข่าวภัยภิบัติ",
    ],
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    model: 0,
    video: {
      sources: [
        {
          src: "https://www.w3schools.com/html/mov_bbb.mp4",
          type: "video/mp4",
        },
      ],
      options: {
        autoplay: true,
        volume: 0.6,
        poster: "https://www.w3schools.com/html/mov_bbb.mp4",
      },
    },
    linkImages: [
      "https://research.tndr-tdpf.info/images/logo/new_slide-01.png",
      "https://research.tndr-tdpf.info/images/logo/new_slide-02.png",
      "https://research.tndr-tdpf.info/images/logo/new_slide-03.png",
      "https://research.tndr-tdpf.info/images/logo/new_slide-04.png",
      "https://research.tndr-tdpf.info/images/logo/new_slide-05.png",
      "https://research.tndr-tdpf.info/images/logo/new_slide-06.png",
      "https://research.tndr-tdpf.info/images/logo/new_slide-07.png",
      "https://research.tndr-tdpf.info/images/logo/new_slide-08.png",
      "https://research.tndr-tdpf.info/images/logo/new_slide-09.png",
      "https://research.tndr-tdpf.info/images/logo/new_slide-19.png",
    ],
    slides: [],
    filterDuplicateOpenLink: true,
  }),
  methods: {
    openReferenceLink(link) {
      console.log(`>>> open link`);
      if (this.filterDuplicateOpenLink) {
        window.open(link);
        this.filterDuplicateOpenLink = false;
      } else {
        this.filterDuplicateOpenLink = true;
      }
    },
    text_truncate(str, length, ending) {
      if (length == null) {
        length = 100;
      }
      if (ending == null) {
        ending = "...";
      }
      if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
      } else {
        return str;
      }
    },
    handleReadMore(itemId) {
      if (this.tab === 0) {
        this.$router.push({ path: `/news/${itemId}` });
      } else if (this.tab === 1) {
        this.$router.push({ path: `/gallery/${itemId}` });
      } else if (this.tab === 2) {
        this.$router.push({ path: `/conference-seminar/${itemId}` });
      } else if (this.tab === 3) {
        this.$router.push({ path: `/news-disaster/${itemId}` });
      }
    },
    dynamicSort(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function(a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    },
    myEventHandler() {
      const w = window.innerWidth;
      // console.log(w);
      if (w >= 576) {
        this.linkImages = [
          "https://research.tndr-tdpf.info/images/logo/new_slide-01.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-02.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-03.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-04.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-05.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-06.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-07.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-08.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-09.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-19.png",
        ];
      } else {
        this.linkImages = [
          "https://research.tndr-tdpf.info/images/logo/new_slide-10.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-11.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-12.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-13.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-14.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-15.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-16.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-17.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-18.png",
          "https://research.tndr-tdpf.info/images/logo/new_slide-20.png",
        ];
      }
    },
  },
  created() {
    this.$store.dispatch("pageLoading", true);
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    console.log(">>> home page mounted");

    this.$axios
      .get("https://research.tndr-tdpf.info/api/fetch/home-page")
      .then((res) => {
        const data = res.data;
        this.newsItems.push(data.news);
        this.newsItems.push(data.activity_picture);
        this.newsItems.push(data.conference_seminar);
        this.newsItems.push(data.news_disaster);
        this.activityPictureMini = data.activity_picture_limit;
        this.otherLinks = data.other_link;
        this.slides = data.slide.sort(this.dynamicSort("sort"));
      })
      .then(() => {
        this.$store.dispatch("pageLoading", false);
      });
  },
};
</script>
<style scoped>
.v-card--reveal {
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}

.v-application--wrap {
  min-height: 100vh;
  justify-content: center;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
