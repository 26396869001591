<template>
  <div>
    <v-container class="section-disaster-expert-inventory pt-16 pb-16">
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <h2 class="font-weight-bold" style="font-size: 32px">งานวิชาการ</h2>

            <v-row class="pt-8">
              <v-col cols="12" sm="12" md="2">
                <span style="font-size: 20px; line-height: 50px">
                  เลือกหมวดหมู่
                </span>
              </v-col>

              <v-col cols="12" sm="12" md="3">
                <v-select
                  solo
                  v-model="categorySelected"
                  @change="changeCategory"
                  :items="categories"
                  item-text="state"
                  item-value="abbr"
                  label="Select"
                  persistent-hint
                  return-object
                  single-line
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="7">
                <v-text-field
                  solo
                  label="ชื่องานวิชาการ"
                  prepend-inner-icon="mdi-magnify"
                  v-model="searchInput"
                  @keyup="changeSearchInput"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="pt-8">
              <v-col
                class="text-left"
                cols="12"
                sm="12"
                md="6"
                lg="4"
                v-for="(item, index) in publication"
                :key="index"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card class="mx-auto" color="grey lighten-4">
                    <v-img
                      width="100%"
                      height="268"
                      :src="item.publication_thumbnail"
                      cover
                    >
                      <v-expand-transition>
                        <div
                          v-if="hover"
                          class="d-flex flex-column align-center justify-center transition-fast-in-fast-out bg-primary darken-2 v-card--reveal  white--text text-center"
                          style="height: 100%"
                        >
                          <span
                            style="font-size: 18px; //border: 1px solid yellow"
                          >
                            <span v-text="item.publication_name"></span>
                          </span>
                          <div
                            class="d-flex flex-column my-4"
                            style="font-size: 18px; //border: 1px solid yellow"
                          >
                            <small v-text="item.th_full_name"></small>
                            <small v-text="item.en_full_name"></small>
                          </div>
                          <v-btn
                            style="//border: 1px solid yellow"
                            class="bg-primary-red white--text"
                            @click="
                              $router.push({
                                path: `/publication/${item.id}`,
                              })
                            "
                            >ดูเพิ่มเติม</v-btn
                          >
                        </div>
                      </v-expand-transition>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchInput: "",
      categorySelected: "",
      categories: ["รายการ"],
      publication: [],
      bufferPublication: [],
    };
  },
  created() {
    this.$store.dispatch("pageLoading", true);
  },
  methods: {
    fetchPublications() {
      this.$axios
        .get(`https://research.tndr-tdpf.info/api/fetch/publication-category`)
        .then((res) => {
          const data = res.data;
          let rawData = [];
          data.forEach((item) => {
            rawData.push(item.category_name);
          });

          this.categories = rawData;
          if (rawData.length > 0) {
            this.categorySelected = rawData[0];

            // fetch publication from category name
            this.$axios
              .post(
                `https://research.tndr-tdpf.info/api/fetch/publication-from-category`,
                {
                  category_name: this.categorySelected,
                }
              )
              .then((res) => {
                const data = res.data;
                this.publication = data;
                this.bufferPublication = data;
                this.$store.dispatch("pageLoading", false);
              });
          }
        });
    },
    fetchPublicationByCategory() {
      this.$axios
        .post(
          `https://research.tndr-tdpf.info/api/fetch/publication-from-category`,
          { category_name: this.categorySelected }
        )
        .then((res) => {
          const data = res.data;
          this.publication = data;
          this.bufferPublication = data;
        });
    },
    changeCategory() {
      console.log(`>>> change category`);
      this.fetchPublicationByCategory();
    },
    changeSearchInput() {
      this.publication = this.bufferPublication;
      let keyword = this.searchInput;
      // console.log(keyword);
      let found = [];
      const query = this.publication.map((el) => el.publication_name);
      let regex = new RegExp(keyword, "i");
      query.filter((item, index) => {
        if (regex.test(item)) {
          found.push(this.publication[index]);
        }
      });

      // if not found from title
      if (found.length == 0) {
        const query = this.publication.map((el) => el.th_full_name);
        let regex = new RegExp(keyword, "i");
        query.filter((item, index) => {
          if (regex.test(item)) {
            found.push(this.publication[index]);
          }
        });
      }

      if (found.length == 0) {
        const query = this.publication.map((el) => el.en_full_name);
        let regex = new RegExp(keyword, "i");
        query.filter((item, index) => {
          if (regex.test(item)) {
            found.push(this.publication[index]);
          }
        });
      }

      this.publication = found;
    },
  },
  mounted() {
    console.log(`>>> publication mounted`);

    this.fetchPublications();
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}

.v-application--wrap {
  min-height: 100vh;
  justify-content: center;
}
</style>
