import History from "../views/pages/about/History.vue";
import Introduction from "../views/pages/about/Introduction.vue";
import Network from "../views/pages/about/Network.vue";
import OrganizationStructure from "../views/pages/about/OrganizationStructure.vue";
import PresidentAdministrativeBoard from "../views/pages/about/PresidentAdministrativeBoard.vue";
import VisionStrategy from "../views/pages/about/VisionStrategy.vue";

export default [
  {
    // about
    path: "/history",
    name: "history",
    component: History,
  },
  {
    path: "/introduction",
    name: "Introduction",
    component: Introduction,
  },
  {
    path: "/networking",
    name: "Network",
    component: Network,
  },
  {
    path: "/organization-structure",
    name: "OrganizationStructure",
    component: OrganizationStructure,
  },
  {
    path: "/president-administrative-board",
    name: "PresidentAdministrativeBoard",
    component: PresidentAdministrativeBoard,
  },
  {
    path: "/vision-strategy",
    name: "VisionStrategy",
    component: VisionStrategy,
  },
];
