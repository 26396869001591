<template>
  <div>
    <v-container class="section-disaster-expert-inventory pt-16 pb-16">
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <v-row>
              <v-col class="d-flex flex-column" cols="12" sm="9">
                <h2 class="font-weight-bold" style="font-size: 32px">
                  ฐานข้อมูลผู้เชี่ยวชาญ
                </h2>
              </v-col>
              <v-col class="d-flex flex-column" cols="12" sm="3">
                <v-btn
                  class="bg-primary-red white--text"
                  depressed
                  @click="$router.push({ path: '/post/1' })"
                  >การแบ่งกลุ่มความเชี่ยวชาญ</v-btn
                >
              </v-col>
            </v-row>
            <v-row class="pt-8">
              <v-col class="d-flex flex-column" cols="12" sm="6" md="4" lg="3">
                <p class="font-weight-bold" style="font-size: 24px">ภูมิภาค</p>
                <v-select
                  :items="region"
                  v-model="regionSelected"
                  @change="changeDropdown"
                  label="เลือกภูมิภาค"
                  item-text="label"
                  item-value="code"
                  solo
                ></v-select>
              </v-col>
              <v-col class="d-flex flex-column" cols="12" sm="6" md="4" lg="3">
                <p class="font-weight-bold" style="font-size: 24px">สถาบัน</p>
                <v-select
                  :items="institution"
                  v-model="institutionSelected"
                  @change="changeDropdown"
                  label="เลือกสถาบัน"
                  solo
                ></v-select>
              </v-col>
              <v-col class="d-flex flex-column" cols="12" sm="6" md="4" lg="3">
                <p class="font-weight-bold" style="font-size: 24px">
                  <span class="mr-2">กลุ่มภัยพิบัติ</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      ความเชี่ยวชาญต่อกลุ่มภัยหลัก (Hazard Family Expertise: HZE)<br>
                      หรือความเชี่ยวชาญทั่วไปกลุ่มหลัก (General Expertise: GE)
                    </span>
                  </v-tooltip>
                </p>
                <v-select
                  :items="expertise"
                  v-model="expertiseSelected"
                  @change="changeDropdown"
                  label="เลือกกลุ่มภัยพิบัติ"
                  solo
                ></v-select>
              </v-col>
              <v-col class="d-flex flex-column" cols="12" sm="6" md="4" lg="3">
                <p class="font-weight-bold white--text" style="font-size: 24px">
                  .
                </p>
                <v-text-field
                  v-model="searchExpertiseByName"
                  @keyup="changeSearchExpertise"
                  solo
                  label="ชื่อภาษาไทย หรืออังกฤษ"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
              <v-col class="text-left" cols="12">
                <p style="font-size: 24px" class="font-weight-bold">
                  {{ getRegionText(regionSelected) }}
                </p>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    v-for="(user, index) in users"
                    :key="index"
                  >
                    <v-card
                      class="mx-auto cursor-pointer pt-2 card-info"
                      @click="onClickDisasterExpertInventory(user)"
                    >
                      <v-row>
                        <v-col cols="12" sm="12" md="4">
                          <div class="py-4 pl-8">
                            <v-img
                              v-if="user.photo.toString().length > 1"
                              style="width: 150px !important; height: 150px !important; border-radius: 50% !important; margin: 0 auto"
                              :src="
                                `${$store.state.apiDomain}/storage/${user.photo}`
                              "
                              cover
                            ></v-img>
                            <v-img
                              v-else
                              style="width: 150px !important; height: 150px !important; border-radius: 50% !important; margin: 0 auto; background-color:#f2f2f2"
                              cover
                            ></v-img>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="8" class="pl-6">
                          <p class="ma-0">{{ user.th_name }}</p>
                          <p>{{ user.en_name }}</p>
                          <p class="ma-0">สถาบัน : {{ user.institution }}</p>
                          <p class="ma-0">
                            ความเชี่ยวชาญ
                            <ul>
                              <li v-for="(item, index) in JSON.parse(user.primary_expertise)" :key="`p-${index}`">{{ item }}</li>
                            </ul>
                          </p>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ExpertInventory",
  data() {
    return {
      users: [],
      bufferUsers: [],
      regionSelected: "ทั้งหมด",
      institutionSelected: "ทั้งหมด",
      expertiseSelected: "ทั้งหมด",
      region: [
        {
          code: "ทั้งหมด",
          label: "ทั้งหมด",
        },
        {
          code: "N",
          label: "ภาคเหนือ",
        },
        {
          code: "NE",
          label: "ภาคตะวันออกเฉียงเหนือ",
        },
        {
          code: "C",
          label: "ภาคกลาง",
        },
        {
          code: "E",
          label: "ภาคตะวันออก",
        },
        {
          code: "W",
          label: "ภาคตะวันตก",
        },
        {
          code: "S",
          label: "ภาคใต้",
        },
      ],
      institution: ["ทั้งหมด"],
      expertise: ["ทั้งหมด"],
      searchExpertiseByName: "",
    };
  },
  methods: {
    fetchUser() {
      const apiDomain = this.$store.state.apiDomain;

      let formData = {
          region: this.regionSelected,
          institution: this.institutionSelected,
          expertise: this.expertiseSelected,
        }

        console.log(`>>> formData`, formData)
      this.$store.dispatch("pageLoading", true);
      this.$axios
        .post(`${apiDomain}/api/fetch/filter-user`, formData)
        .then((res) => {
          const data = res.data

          // sync data
          let institution = [];
          let expertise = [];
          this.users = data;
          this.bufferUsers = data;

          data.forEach((item) => {
            institution.push(item.institution);
            expertise.push(...JSON.parse(item.primary_expertise));
            // expertise.push(...JSON.parse(item.second_expertise));
            // expertise.push(...JSON.parse(item.third_expertise));
          });

          // console.log(`>>>debug`, expertise)

          institution = institution.filter(
            (item, index) => institution.indexOf(item) === index
          );

          expertise = expertise.filter(
            (item, index) => expertise.indexOf(item) === index
          );

          institution = institution.sort();
          expertise = expertise.sort();

          this.institution.push(...institution);
          this.expertise.push(...expertise);
        }).then(() => {
          this.$store.dispatch("pageLoading", false);
        })
    },
    getRegionText(code) {
      let v = "";
      this.region.forEach((item) => {
        if (item.code == code) {
          v = item.label;
        }
      });
      return v;
    },
    changeDropdown() {
      console.log(`>>> change dropdown`)
      this.fetchUser()
    },
    onClickDisasterExpertInventory(user) {
      this.$store.dispatch("purposeDialog", {
        type: "expert",
        open: true,
        userExpertId: user.id,
      });
    },
    changeSearchExpertise() {
      this.users = this.bufferUsers
      const keyword = this.searchExpertiseByName;
      let found = [];

      const names = this.users.map((el) => el.th_name);
      let regex = new RegExp(keyword, "i");
      names.filter((item, index) => {
        if (regex.test(item)) {
          // console.log(index);
          found.push(this.users[index]);
        }
      });

      if (found.length == 0) {
        // search from english name
        const names = this.users.map((el) => el.en_name);

        names.filter((item, index) => {
        if (regex.test(item)) {
          // console.log(index);
          found.push(this.users[index]);
        }
      });
      }

      this.users = found
    },
  },
  created() {
    this.$store.dispatch("pageLoading", true);
  },
  mounted() {
    console.log(`>>> expert inventory mounted`);
      this.fetchUser()
  },
};
</script>

<style scoped>
</style>
