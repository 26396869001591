<template>
  <v-container class="section-disaster-expert-inventory py-10">
    <v-row>
      <v-col cols="12" md="2" style="//border: 1px solid red">
        <!-- <v-img
          style="width: 150px !important; height: 150px !important; border-radius: 50% !important; margin: 0 auto; background-color:#f2f2f2"
          cover
        ></v-img> -->

        <v-img
          v-if="photo.length > 1"
          style="width: 150px !important; height: 150px !important; border-radius: 50% !important; margin: 0 auto"
          :src="`${$store.state.apiDomain}/storage/${photo}`"
          cover
        ></v-img>

        <v-img
          v-else
          style="width: 150px !important; height: 150px !important; border-radius: 50% !important; margin: 0 auto; background-color:#f2f2f2"
          cover
        ></v-img>
      </v-col>
      <v-col cols="12" md="10" class="pl-8" style="//border: 1px solid red">
        <p class="mb-0">{{ thName }}</p>
        <p>{{ enName }}</p>
        <p class="mb-0">สถาบัน : {{ institution }}</p>
        <!-- <p>ความเชี่ยวชาญ : {{ primaryExpertise[0] }}</p> -->
      </v-col>
      <v-col cols="12" style="//border: 1px solid red">
        <p class="mb-0">วุฒิการศึกษาระดับปริญญาตรี</p>
        <ul class="pl-12">
          <li
            v-for="(item, index) in bachelorDegree"
            :key="`bachelorDegree-${index}`"
          >
            มหาวิทยาลัย {{ item[0] }} สาขา
            {{ item[1] }}
          </li>
        </ul>
        <p class="mb-0 pt-2">วุฒิการศึกษาระดับปริญญาโท</p>
        <ul class="pl-12">
          <li
            v-for="(item, index) in masterDegree"
            :key="`bachelorDegree-${index}`"
          >
            มหาวิทยาลัย {{ item[0] }} สาขา
            {{ item[1] }}
          </li>
        </ul>
        <p class="mb-0 pt-2">วุฒิการศึกษาระดับปริญญาเอก</p>
        <ul class="pl-12">
          <li
            v-for="(item, index) in phdDegree"
            :key="`bachelorDegree-${index}`"
          >
            มหาวิทยาลัย {{ item[0] }} สาขา
            {{ item[1] }}
          </li>
        </ul>
      </v-col>
      <v-col cols="12" style="//border: 1px solid red">
        <p class="mb-0">งานวิจัย</p>
        <ul class="pl-12">
          <li
            v-for="(item, index) in publications_research"
            :key="index"
            v-text="item.publication_name"
          ></li>
        </ul>
      </v-col>
      <v-col cols="12" style="//border: 1px solid red">
        <p class="mb-0">สื่อสิ่งพิมพ์ ตำรา</p>
        <ul class="pl-12">
          <li
            v-for="(item, index) in publications_post"
            :key="index"
            v-text="item.publication_name"
          ></li>
        </ul>
      </v-col>
      <v-col cols="12" style="//border: 1px solid red">
        <p class="mb-0">ประสบการณ์ทำงานด้านภัยพิบัติ</p>
        <ul class="pl-12">
          <li
            v-for="(item, index) in workExperience"
            :key="`workExperience-${index}`"
          >
            {{ item }}
          </li>
        </ul>
      </v-col>
      <v-col cols="12" style="//border: 1px solid red">
        <p class="mb-0">ความเชี่ยวชาญต่อกลุ่มภัยหลัก</p>
        <ul class="pl-12">
          <li
            v-for="(item, index) in primaryExpertise"
            :key="`primaryExpertise-${index}`"
          >
            {{ item }}
          </li>
        </ul>
      </v-col>

      <v-col cols="12" style="//border: 1px solid red">
        <p class="mb-0">ความเชี่ยวชาญต่อเหตุการณ์ภัยหลัก</p>
        <ul class="pl-12">
          <li
            v-for="(item, index) in secondExpertise"
            :key="`secondExpertise-${index}`"
          >
            {{ item }}
          </li>
        </ul>
      </v-col>

      <v-col cols="12" style="//border: 1px solid red">
        <p class="mb-0">ความเชี่ยวชาญต่อภัยอันตรายที่เกิดขึ้น</p>
        <ul class="pl-12">
          <li
            v-for="(item, index) in thirdExpertise"
            :key="`thirdExpertise-${index}`"
          >
            {{ item }}
          </li>
        </ul>
      </v-col>

      <v-col cols="12" style="//border: 1px solid red">
        <p class="mb-0">ติดต่อผู้เชี่ยวชาญ</p>
        <p class="mb-0">E-mail : {{ email }}</p>
        <ul style="list-style: none" class="pa-0">
          <li
            v-for="(item, index) in storageLink"
            :key="`storage-link-${index}`"
          >
            {{ item }}
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ExpertDetail",
  data() {
    return {
      photo: "",
      userId: "",
      institution: "",
      thName: "",
      enName: "",
      email: "",
      bachelorDegree: [],
      masterDegree: [],
      phdDegree: [],
      primaryExpertise: [],
      secondExpertise: [],
      thirdExpertise: [],
      workExperience: [],
      storageLink: [],
      publications_research: [],
      publications_post: [],
    };
  },
  mounted() {
    console.log(`>>> expert inventory - search result`);

    const storageId = this.$route.params.data;
    const data = JSON.parse(window.localStorage.getItem(storageId));

    console.log(`>>> check search code`, data);

    if (data === null) {
      this.$router.push({
        path: "/expert-inventory",
      });
    } else {
      this.userId = data.id;
      this.institution = data.institution;
      this.email = data.email;
      this.photo = data.photo;

      this.thName = `${data.th_prefix} ${data.th_first_name} ${data.th_last_name}`;
      this.enName = `${data.en_prefix}${data.en_first_name} ${data.en_last_name}, ${data.en_suffix}`;

      let bachelorDegree = JSON.parse(data.bachelor_degree);
      let masterDegree = JSON.parse(data.master_degree);
      let phdDegree = JSON.parse(data.phd_degree);

      let queryBachelorDegree = [];
      let queryMasterDegree = [];
      let queryPhdDegree = [];

      bachelorDegree.university.forEach((item, index) => {
        queryBachelorDegree.push([item, bachelorDegree.field_of_study[index]]);
      });

      masterDegree.university.forEach((item, index) => {
        queryMasterDegree.push([item, masterDegree.field_of_study[index]]);
      });

      phdDegree.university.forEach((item, index) => {
        queryPhdDegree.push([item, phdDegree.field_of_study[index]]);
      });

      this.bachelorDegree = queryBachelorDegree;
      this.masterDegree = queryMasterDegree;
      this.phdDegree = queryPhdDegree;

      this.primaryExpertise = JSON.parse(data.primary_expertise);
      this.secondExpertise = JSON.parse(data.second_expertise);
      this.thirdExpertise = JSON.parse(data.third_expertise);
      this.workExperience = JSON.parse(data.work_experience);

      data.publications.forEach((item) => {
        if (item.publication_category_id == 1) {
          this.publications_research.push(item);
        } else {
          this.publications_post.push(item);
        }
      });

      data.storage_link.split(",").forEach((item) => {
        if (item !== "-") {
          this.storageLink.push(item);
        }
      });
    }
  },
};
</script>

<style scoped>
.section-disaster-expert-inventory {
  min-height: auto;
}
</style>
