<template>
  <div>
    <static-article />
  </div>
</template>

<script>
import StaticArticle from "../../layouts/StaticArticle";

export default {
  name: "History",
  components: {
    StaticArticle,
  },
  created() {
    this.$store.dispatch("pageLoading", true);
  },
  mounted() {
    let layouts = this.$store.state.layouts;
    layouts.articleLayout.fullPage = true;
    this.$store.dispatch("layouts", layouts);
    // this.$store.dispatch("bannerText", "ประวัติความเป็นมา");

    this.$axios
      .get(`https://research.tndr-tdpf.info/api/fetch/page/1`)
      .then((res) => {
        const data = res.data;
        console.log(data);
        this.$store
          .dispatch("setPageContent", {
            bannerText: data.page_title,
            pageContent: data.page_detail,
          })
          .then(() => {
            this.$store.dispatch("pageLoading", false);
          });
      });
  },
};
</script>

<style scoped>
.c-main-content {
  background-color: #f5f5f5;
  min-height: 600px;
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 1200px;
  }
}
</style>
