import { render, staticRenderFns } from "./PresidentAdministrativeBoard.vue?vue&type=template&id=b92c8732&scoped=true"
import script from "./PresidentAdministrativeBoard.vue?vue&type=script&lang=js"
export * from "./PresidentAdministrativeBoard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b92c8732",
  null
  
)

export default component.exports