import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/pages/home/Home.vue";
import About from "./about";
import News from "./news";
import knowledge from "./knowledge";
import course from "./course";
import Contact from "../views/pages/contact/Contact.vue";
import Sitemap from "../views/pages/contact/Sitemap.vue";

/* Disaster */
import ExpertDetail from "../views/pages/knowledge/expert/ExpertDetail.vue";
import PublicationDetail from "../views/pages/knowledge/publication/PublicationDetail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  ...About,
  ...News,
  ...knowledge,
  ...course,
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/site-map",
    name: "sitemap",
    component: Sitemap,
  },
  {
    // DisasterExpertInventory
    path: "/disaster-expert-inventory/:data",
    name: "read-disaster-expert-inventory",
    component: ExpertDetail,
  },
  {
    // DisasterPublicationInventory
    path: "/disaster-publication-inventory/:id",
    name: "read-disaster-publication-inventory",
    component: PublicationDetail,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
