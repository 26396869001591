<template>
  <div>
    <banner />
    <div class="c-main-content pt-10 pb-10">
      <v-container max-wdith="1200px">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            :md="$store.state.layouts.articleLayout.fullPage ? 12 : 9"
          >
            <v-card class="mx-auto pa-10 mb-6">
              <static-article-header />
              <div>
                <h5
                  class="text-h5 font-weight-bold"
                  v-text="$store.state.pageTitle"
                ></h5>
                <br />
                <div v-html="$store.state.pageContent"></div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Banner from "../../layouts/Banner";
export default {
  name: "Introduction",
  components: {
    Banner,
  },
  created() {
    this.$store.dispatch("pageLoading", true);
  },
  mounted() {
    let layouts = this.$store.state.layouts;
    layouts.articleLayout.fullPage = true;
    this.$store.dispatch("layouts", layouts);
    // this.$store.dispatch("bannerText", "วิดีโอแนะนำเครือข่าย");

    this.$axios
      .get(`https://research.tndr-tdpf.info/api/fetch/page/6`)
      .then((res) => {
        const data = res.data;
        console.log(data);
        this.$store
          .dispatch("setPageContent", {
            bannerText: data.page_title,
            pageContent: data.page_detail,
          })
          .then(() => {
            this.$store.dispatch("pageLoading", false);
          });
      });
  },
};
</script>
