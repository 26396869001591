<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-dialog
      v-model="$store.state.purposeDialog.open"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">จุดประสงค์ที่ต้องการข้อมูล</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="formData.name"
                  :rules="formData.nameRules"
                  label="ชื่อ"
                  hide-details
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="formData.email"
                  label="อีเมล"
                  hide-details
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="formData.institution"
                  :rules="formData.institutionRules"
                  label="ชื่อหน่วยงาน"
                  outlined
                  hide-details
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="categoryInstitution"
                  item-text="label"
                  item-value="code"
                  v-model="categoryInstitutionSelected"
                  :rules="[(v) => !!v || 'Field is required']"
                  @change="changeCategoryInstitution"
                  label="ประเภทหน่วยงาน"
                  solo
                  hide-details
                  required
                ></v-select>
                <v-text-field
                  v-if="categoryInstitutionOther"
                  hide-details
                  label="ระบุสถาบัน"
                  clearable
                  v-model="categoryInstitutionOtherText"
                  :rules="formData.categoryInstitutionOther"
                  :required="categoryInstitutionSelected === 'D'"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="objective"
                  item-text="label"
                  item-value="code"
                  v-model="objectiveSelected"
                  :rules="[(v) => !!v || 'Field is required']"
                  @change="changeObjective"
                  label="วัตถุประสงค์"
                  solo
                  required
                ></v-select>
                <v-text-field
                  v-if="objectiveOther"
                  hide-details
                  label="โปรดระบุ"
                  clearable
                  v-model="objectiveOtherText"
                  :rules="formData.objectiveOther"
                  :required="categoryInstitutionSelected === 'C'"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closePurposeDialog">
            ยกเลิก
          </v-btn>
          <v-btn color="blue darken-1" text @click="submit" :disabled="!valid">
            ดำเนินการ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      select: null,
      checkbox: false,
      formData: {
        name: "",
        nameRules: [
          (v) => !!v || "Name is required",
          (v) =>
            (v && v.length <= 100) || "Name must be less than 10 characters",
        ],
        email: "",
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        institution: "",
        institutionRules: [
          (v) => !!v || "Institution is required",
          (v) =>
            (v && v.length <= 50) || "Name must be less than 10 characters",
        ],
        categoryInstitutionOther: [
          (v) => !!v || "Institution is required",
          (v) => (v && v.length <= 50) || "",
        ],
        objectiveOther: [
          (v) => !!v || "Institution is required",
          (v) => (v && v.length <= 100) || "",
        ],
      },
      categoryInstitution: [
        {
          code: "A",
          label: "ราชการ",
        },
        {
          code: "B",
          label: "เอกชน",
        },
        {
          code: "C",
          label: "บุคคลทั่วไป",
        },
        {
          code: "D",
          label: "อาจารย์ นักเรียน นักศึกษา (ระบุสถาบัน)",
        },
      ],
      objective: [
        {
          code: "A",
          label: "นำข้อมูลไปทำวิจัย",
        },
        {
          code: "B",
          label: "แก้ปัญหาภัยพิบัติในชุมชน",
        },
        {
          code: "C",
          label: "ความสนใจส่วนบุคคล อื่นๆ (โปรดระบุ)",
        },
      ],
      categoryInstitutionSelected: null,
      objectiveSelected: null,
      categoryInstitutionOther: false,
      objectiveOther: false,
      categoryInstitutionOtherText: null,
      objectiveOtherText: null,
    };
  },
  methods: {
    closePurposeDialog() {
      this.$store.dispatch("purposeDialog", {
        open: false,
        type: "",
        userExpertId: 0,
      });
    },
    changeCategoryInstitution() {
      if (this.categoryInstitutionSelected === "D") {
        this.categoryInstitutionOther = true;
      } else {
        this.categoryInstitutionOther = false;
      }
    },
    changeObjective() {
      if (this.objectiveSelected === "C") {
        this.objectiveOther = true;
      } else {
        this.objectiveOther = false;
      }
    },
    download(fileUrl) {
      window.open(fileUrl);
    },
    submit() {
      let check = this.$refs.form.validate();
      let then = this;
      if (check) {
        console.log(`>>> create request`);
        const apiDomain = this.$store.state.apiDomain;
        let formData = {
          name: this.formData.name,
          email: this.formData.email,
          institution: this.formData.institution,
          category_institution: this.categoryInstitutionSelected,
          other_category_institution: this.categoryInstitutionOtherText,
          objective: this.objectiveSelected,
          other_objective: this.objectiveOtherText,
        };
        let urlRequest = "";

        if (this.$store.state.purposeDialog.type === "expert") {
          formData.expertise_id = this.$store.state.purposeDialog.userExpertId;
          urlRequest = `${apiDomain}/api/request/expertise-info`;
        } else {
          formData.publication_id = this.$store.state.purposeDialog.publicationId;
          urlRequest = `${apiDomain}/api/request/publication-info`;
        }

        console.log(formData);
        this.$store.dispatch("pageLoading", true);
        this.$axios.post(urlRequest, formData).then((res) => {
          const data = res.data;

          console.log(data);
          if (this.$store.state.purposeDialog.type === "expert") {
            this.$store
              .dispatch("expertInventoryResult", data)
              .then(function() {
                then.formData.name = "";
                then.formData.email = "";
                then.formData.institution = "";
                then.formData.categoryInstitutionSelected = null;
                then.formData.objectiveSelected = null;
                then.closePurposeDialog();

                const randomString = Math.random()
                  .toString(36)
                  .substring(7);
                window.localStorage.setItem(randomString, JSON.stringify(data));

                window.open(`/expert-inventory/${randomString}`, "_blank");
              })
              .then(() => {
                this.$store.dispatch("pageLoading", false);
              });
          } else {
            this.download(data);
            this.closePurposeDialog();
            this.$store.dispatch("pageLoading", false);
          }
        });
      }
    },
  },
};
</script>

<style></style>
