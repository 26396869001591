<template>
  <div>
    <banner />
    <v-container fluid style="padding-top:60px; min-height: 800px">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="3"
          xl="3"
          v-for="(item, menuIndex) in $store.state.menu"
          :key="menuIndex"
        >
          <div style="min-height: 200px">
            <div>
              <h3 style="font-size: 28px" v-text="item.title"></h3>
              <div class="py-4">
                <!-- <v-treeview open-all :items="items"></v-treeview> -->
                <ul>
                  <li
                    v-for="(item, index) in item.subMenu"
                    :key="`sub-menu-${index}`"
                  >
                    <a :href="item.link" v-if="menuIndex !== 5">{{
                      item.title
                    }}</a>
                    <a :href="item.link" v-else target="_blank">{{
                      item.title
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Banner from "../../layouts/Banner";

export default {
  name: "Sitemap",
  components: {
    Banner,
  },
  data: () => ({
    items: [
      {
        id: 1,
        name: "Applications :",
        children: [
          { id: 2, name: "Calendar : app" },
          { id: 3, name: "Chrome : app" },
          { id: 4, name: "Webstorm : app" },
        ],
      },
    ],
  }),
  mounted() {
    this.$store.dispatch("sectionFooter", false);
    this.$store.dispatch("bannerText", "แผนผังเว็บไซต์");
  },
};
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .container {
    max-width: 1200px;
    /* border: 1px solid red; */
  }
}
</style>
