<template>
  <div>
    <banner />
    <div class="c-main-content pt-10 pb-10">
      <v-container max-width="1200px">
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto pa-10 mb-6">
              <div v-if="$store.state.layouts.articleHeaderStyle === 'clean'">
                <h5
                  class="text-h5 font-weight-bold"
                  v-text="$store.state.pageTitle"
                ></h5>
                <br />
                <div v-html="$store.state.pageContent"></div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Banner from "../../layouts/Banner";

export default {
  name: "GalleryDetail",
  components: { Banner },
  created() {
    this.$store.dispatch("pageLoading", true);
  },
  mounted() {
    const dataId = this.$route.params.id;
    const domain = this.$store.state.apiDomain;

    this.$store.dispatch("bannerText", "ภาพกิจกรรม");
    // fetch detail
    this.$axios
      .get(`${domain}/api/fetch/activity-picture/show/${dataId}`)
      .then((res) => {
        const data = res.data;

        console.log(data);
        this.$store
          .dispatch("pageContentWithTitle", {
            pageTitle: data.activity_title,
            pageContent: data.activity_detail,
          })
          .then(() => {
            this.$store.dispatch("pageLoading", false);
          });
      });
  },
};
</script>

<style scoped>
.c-main-content {
  background-color: #f5f5f5;
  min-height: 600px;
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 1200px;
  }
}
</style>
