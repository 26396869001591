import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiDomain: "https://research.tndr-tdpf.info",
    pageLoading: false,
    navDrawer: false,
    bannerText: "",
    pageTitle: "",
    pageContent: "",
    currentPage: "",
    layouts: {
      articleHeaderStyle: "clean", // clean, normal
      articleLayout: {
        fullPage: false,
      },
    },
    sectionFooter: true,
    purposeDialog: {
      type: "",
      open: false,
      userExpertId: 0,
      publicationId: 0,
    },
    expertInventoryResult: {},
    menu: [
      {
        title: "รู้จักเรา",
        icon: "mdi-account-group",
        subMenu: [
          { title: "ประวัติความเป็นมา", link: "/history" },
          { title: "วิสัยทัศน์ / พันธกิจ", link: "/vision-strategy" },
          { title: "โครงสร้างองค์กร", link: "/organization-structure" },
          {
            title: "คณะกรรมการบริหาร",
            link: "/president-administrative-board",
          },
          { title: "เครือข่ายความร่วมมือ", link: "/networking" },
          { title: "วิดีโอแนะนำเครือข่าย", link: "/introduction" },
        ],
      },
      {
        title: "ข่าวสาร",
        icon: "mdi-newspaper-variant-outline",
        subMenu: [
          { title: "ข่าวประชาสัมพันธ์", link: "/news" },
          { title: "ภาพกิจกรรม", link: "/gallery" },
          { title: "ปฏิทินกิจกรรม", link: "/calendar" },
          {
            title: "งานประชุมและสัมมนาวิชาการ",
            link: "/conference-seminar",
          },
          { title: "สรุปข่าวภัยพิบัติ", link: "/news-disaster" },
        ],
      },
      {
        title: "คลังความรู้ภัยพิบัติ",
        icon: "mdi-book-open-variant",
        subMenu: [
          {
            title: "ข้อมูลผู้เชี่ยวชาญ",
            link: "/expert-inventory",
          },
          {
            title: "ข้อมูลงานวิจัย",
            link: "/research-inventory",
          },
          {
            title: "หนังสือและบทความ",
            link: "/post",
          },
          {
            title: "ผลการประชุมและสัมนาวิชาการ",
            link: "/result-conference-seminar",
          },
          {
            title: "ระบบเชื่อมโยงฐานข้อมูลภัยพิบัติ",
            link: "/data-link-system",
          },
          {
            title: "หลักสูตรการเรียนการสอน",
            link: "/course-academic",
          },
        ],
      },
      {
        title: "บริการวิชาการ",
        icon: "mdi-book-open-variant",
        subMenu: [{ title: "รายการหลักสูตร", link: "/course" }],
      },
      {
        title: "ติดต่อเรา",
        icon: "mdi-email",
        subMenu: [
          { title: "ติดต่อเครือข่าย", link: "/contact" },
          { title: "แผนผังเว็บไซต์", link: "/site-map" },
        ],
      },
      {
        title: "ลิงก์แนะนำ",
        icon: "mdi-link",
        subMenu: [
          { title: "กรมป้องกันและบรรเทาสาธารณภัย", link: "/" },
          { title: "กรมอุตุนิยมวิทยา", link: "/" },
          { title: "กรมชลประทาน", link: "/" },
          { title: "กรมทรัพยากรน้ำ", link: "/" },
          { title: "กรมทรัพยากรธรณี", link: "/" },
          { title: "กรมเจ้าท่า", link: "/" },
          { title: "สถาบันสารสนเทศ ทรัพยากรน้ำ (องค์การมหาชน)", link: "/" },
          { title: "สถาบันการแพทย์ฉุกเฉิน", link: "/" },
          { title: "สำนักงานระบายน้ำ", link: "/" },
        ],
      },
    ],
  },
  mutations: {
    pageLoading(state, data) {
      state.pageLoading = data;
    },
    navDrawer(state, data) {
      state.navDrawer = data;
    },
    bannerText(state, data) {
      state.bannerText = data;
    },
    layouts(state, data) {
      state.layouts = data;
    },
    sectionFooter(state, data) {
      state.sectionFooter = data;
    },
    purposeDialog(state, data) {
      state.purposeDialog = data;
    },
    expertInventoryResult(state, data) {
      state.expertInventoryResult = data;
    },
    setPageContent(state, data) {
      state.bannerText = data.bannerText;
      state.pageContent = data.pageContent;
    },
    pageContentWithTitle(state, data) {
      state.pageTitle = data.pageTitle;
      state.pageContent = data.pageContent;
    },
    currentPage(state, data) {
      state.currentPage = data;
    },
    menuReferenceLinkUpdate(state, data) {
      state.menu[5].subMenu = data;
    },
  },
  actions: {
    pageLoading(context, data) {
      context.commit("pageLoading", data);
    },
    navDrawer(context, data) {
      context.commit("navDrawer", data);
    },
    bannerText(context, data) {
      context.commit("bannerText", data);
    },
    layouts(context, data) {
      context.commit("layouts", data);
    },
    sectionFooter(context, data) {
      context.commit("sectionFooter", data);
    },
    purposeDialog(context, data) {
      context.commit("purposeDialog", data);
    },
    expertInventoryResult(context, data) {
      context.commit("expertInventoryResult", data);
    },
    setPageContent(context, data) {
      context.commit("setPageContent", data);
    },
    pageContentWithTitle(context, data) {
      context.commit("pageContentWithTitle", data);
    },
    currentPage(context, data) {
      context.commit("currentPage", data);
    },
    menuReferenceLinkUpdate(context, data) {
      context.commit("menuReferenceLinkUpdate", data);
    },
  },
  modules: {},
});
