<template>
  <div>
    <v-container fluid style="background-color: #ffffff" class="ma-0 pa-0">
      <v-row>
        <v-col cols="2" class="ma-0 pa-0 header-height">
          <v-img
            src="@/assets/header-logo.png"
            class="header-logo header-height"
            contain
          ></v-img>
        </v-col>
        <v-col cols="10" class="ma-0 pa-0 header-height">
          <div
            class="d-flex justify-center flex-column header-height"
            style="//border: 1px solid red;"
          >
            <span class="header-text">
              มูลนิธิเตรียมความพร้อมป้องกันภัยพิบัติในประเทศไทย (Thai Disaster
              Preparedness Foundation)
            </span>
            <span class="header-text">
              เครือข่ายพัฒนาความเข้มแข็งต่อภัยพิบัติไทย (Thai Network for
              Disaster Resilience)
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-container fluid style="background-color: #B62A2F">
      <v-row>
        <v-col class="d-flex justify-center" cols="12">
          <div style="width: 1200px; padding: 0 24px 0 24px">
            <v-img src="@/assets/TDPF.jpg" max-width="180" max-height="80" contain></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container> -->
    <v-toolbar class="bg-primary border-none px-10 d-flex justify-center">
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          class="bg-primary white--text hover border-none menu-button"
          @click="$router.push({ name: 'home' })"
        >
          หน้าแรก
        </v-btn>
        <v-menu
          open-on-hover
          offset-y
          transition="slide-x-transition"
          bottom
          right
          v-for="(item, menuIndex) in menu"
          :key="`menu-${menuIndex}`"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="bg-primary white--text hover border-none menu-button"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.title }}
            </v-btn>
          </template>
          <v-list class="pa-0" dense>
            <v-list-item
              v-for="(subMenu, index) in item.subMenu"
              :key="`sub-menu-${index}`"
              class="bg-primary hover"
              @click="openReferenceLink(subMenu.link, menuIndex)"
            >
              <v-list-item-action>
                <v-list-item-title class="white--text">{{
                  subMenu.title
                }}</v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-spacer></v-spacer>

      <v-btn icon @click="navDrawerToggle">
        <v-icon class="white--text">mdi-menu</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    menu: [],
  }),
  methods: {
    navDrawerToggle() {
      this.$store.dispatch("navDrawer", !this.$store.state.navDrawer);
    },
    openReferenceLink(link, index) {
      console.log(`>>> open reference link`, link);
      if (index === 5) {
        window.open(link);
      } else {
        this.$router.push(link);
      }
    },
  },
  mounted() {
    this.menu = this.$store.state.menu;
  },
};
</script>

<style lang="scss">
.v-toolbar__content {
  width: 100% !important;
  max-width: 1200px !important;
}

/* iPhone 6/7/8 */
@media only screen and (max-width: 375px) {
  .header-text {
    font-size: 14px;
    padding-left: 40px;
  }

  .header-logo {
    height: 160px;
    margin-left: 20px;
  }

  .header-height {
    height: 100px;
  }
}

/* iPhone 6/7/8 Plus */
@media only screen and (min-width: 412px) {
  .header-text {
    font-size: 14px;
    padding-left: 40px;
  }

  .header-logo {
    height: 160px;
    margin-left: 20px;
  }

  .header-height {
    height: 100px;
  }
}

/* iPad 11 inch */
@media only screen and (min-width: 674px) {
  .header-text {
    font-size: 20px;
    padding-left: 40px;
  }

  .header-logo {
    height: 160px;
    margin-left: 20px;
  }

  .header-height {
    height: 160px;
  }
}

/* iPad */
@media only screen and (min-width: 768px) {
  .header-text {
    font-size: 24px;
    padding-left: 40px;
  }

  .header-logo {
    height: 160px;
    margin-left: 20px;
  }

  .header-height {
    height: 160px;
  }
}

/* iPad Pro */
@media only screen and (min-width: 1024px) {
  .header-text {
    font-size: 24px;
    padding-left: 40px;
  }

  .header-logo {
    height: 160px;
  }

  .header-height {
    height: 160px;
  }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  .header-text {
    font-size: 28px;
    padding-left: 40px;
  }

  .header-height {
    height: 160px;
  }
}
</style>
