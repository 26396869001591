<template>
  <div>
    <banner />
    <v-container max-width="1200px">
      <v-row>
        <v-col cols="12" :sm="12">
          <v-card class="mx-auto mt-8 mb-8">
            <v-container class="pl-8 pt-5 pr-8 pb-5">
              <v-row>
                <v-col
                  cols="12"
                  lg="4"
                  xl="3"
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <v-hover v-slot:default="{ hover }">
                    <v-card class="mx-auto" color="grey lighten-4">
                      <v-img
                        :src="item.thumbnail"
                        width="100%"
                        height="320"
                        class="thumbnail-height"
                        cover
                      >
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex flex-column align-center justify-center transition-fast-in-fast-out bg-primary darken-2 v-card--reveal display-3 white--text text-center"
                            style="height: 100%;"
                          >
                            <span style="font-size: 18px; line-height: 40px">
                              <span v-text="item.title"></span>
                              <br />
                              <span v-text="item.date_thai"></span>
                            </span>
                            <v-btn
                              class="bg-primary-red white--text mt-4"
                              @click="
                                $router.push({ path: `/gallery/${item.id}` })
                              "
                              >ดูเพิ่มเติม</v-btn
                            >
                          </div>
                        </v-expand-transition>
                      </v-img>
                      <!-- <v-card-text class="pt-6" style="position: relative;">
                        <v-btn absolute color="orange" class="white--text" fab large right top>
                          <v-icon>add</v-icon>
                        </v-btn>
                        <div class="font-weight-light grey--text title mb-2">For the perfect meal</div>
                        <h3 class="display-1 font-weight-light orange--text mb-2">QW cooking utensils</h3>
                        <div class="font-weight-light title mb-2">
                          Our Vintage kitchen utensils delight any chef.<br />
                          Made of bamboo by hand
                        </div>
                      </v-card-text> -->
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Banner from "../../layouts/Banner";

export default {
  components: {
    Banner,
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.$store.dispatch("pageLoading", true);
  },
  mounted() {
    this.$store.dispatch("bannerText", "ภาพกิจกรรม");
    this.$axios
      .get(`https://research.tndr-tdpf.info/api/fetch/activity-picture`)
      .then((res) => {
        const data = res.data;
        let newData = [];

        data.forEach((item) => {
          item.title = item.activity_title;
          item.thumbnail = item.activity_thumbnail;
          item.detail = item.activity_detail;
          delete item.activity_title;
          delete item.activity_thumbnail;
          delete item.activity_detail;

          newData.push(item);
        });

        this.items = newData;
        this.$store.dispatch("pageLoading", false);
      });
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}

.v-application--wrap {
  min-height: 100vh;
  justify-content: center;
}
</style>
