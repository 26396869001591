<template>
  <div>
    <banner />
    <v-container fluid class="py-14">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6">
          <div style="min-height: 400px">
            <div>
              <h3 style="font-size: 28px">ช่องทางการติดต่อ</h3>
              <div class="py-4">
                <p>
                  <v-icon>mdi-facebook</v-icon>
                  <span v-text="facebook"></span>
                </p>
                <p>
                  <v-icon>mdi-phone</v-icon>
                  <span v-text="phoneNumber"></span>
                </p>
                <p>
                  <v-icon>mdi-email</v-icon>
                  <span v-text="email"></span>
                </p>
              </div>
              <h3 style="font-size: 28px">ที่อยู่สำนักงาน</h3>
              <div class="pt-4">
                <p v-html="address"></p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="6">
          <div style="min-height: 400px">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7751.325854892487!2d100.52731482207386!3d13.738845911423192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f2aae33623f%3A0x421e0643a63c2093!2z4LiI4Li44Lis4Liy4Lil4LiH4LiB4Lij4LiT4LmM4Lih4Lir4Liy4Lin4Li04LiX4Lii4Liy4Lil4Lix4Lii!5e0!3m2!1sth!2sth!4v1603309591542!5m2!1sth!2sth"
              width="100%"
              frameborder="0"
              style="border:0; height: calc(50vh - 240px) !important"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Banner from "../../layouts/Banner";

export default {
  name: "Contact",
  components: {
    Banner,
  },
  data() {
    return {
      facebook: "",
      phoneNumber: "",
      email: "",
      address: "",
    };
  },
  created() {
    this.$store.dispatch("pageLoading", true);
  },
  mounted() {
    this.$store.dispatch("sectionFooter", false);
    this.$store.dispatch("bannerText", "ช่องทางการติดต่อ");

    this.$axios
      .get(`https://research.tndr-tdpf.info/api/fetch/contact-us`)
      .then((res) => {
        const data = res.data;

        this.facebook = data.facebook;
        this.phoneNumber = data.phone_number;
        this.email = data.email;
        this.address = data.address;

        this.$store.dispatch("pageLoading", false);
      });
  },
};
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .container {
    max-width: 1200px;
    /* border: 1px solid red; */
  }
}
</style>
