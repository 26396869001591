<template>
  <div>
    <static-article />
  </div>
</template>

<script>
import StaticArticle from "../../layouts/StaticArticle";

export default {
  name: "DataLinkSystem",
  components: {
    StaticArticle,
  },
  created() {
    this.$store.dispatch("pageLoading", true);
  },
  mounted() {
    let layouts = this.$store.state.layouts;
    layouts.articleLayout.fullPage = true;
    this.$store.dispatch("layouts", layouts);
    // this.$store.dispatch("bannerText", "ระบบเชื่อมโยงฐานข้อมูลภัยพิบัติ");

    this.$axios
      .get(`https://research.tndr-tdpf.info/api/fetch/page/9`)
      .then((res) => {
        const data = res.data;
        console.log(data);
        this.$store
          .dispatch("setPageContent", {
            bannerText: data.page_title,
            pageContent: data.page_detail,
          })
          .then(() => {
            this.$store.dispatch("pageLoading", false);
          });
      });
  },
};
</script>

<style></style>
