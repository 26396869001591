import Gallery from "../views/pages/news/Gallery.vue";
import GalleryDetail from "../views/pages/news/GalleryDetail.vue";
import Calendar from "../views/pages/news/Calendar.vue";
import News from "../views/pages/news/News.vue";
import NewsDetail from "../views/pages/news/NewsDetail.vue";
import ConferenceSeminar from "../views/pages/news/ConferenceSeminar.vue";
import ConferenceSeminarDetail from "../views/pages/news/ConferenceSeminarDetail.vue";
import NewsDisaster from "../views/pages/news/NewsDisaster.vue";
import NewsDisasterDetail from "../views/pages/news/NewsDisasterDetail.vue";
import StaticArticle from "../views/layouts/StaticArticle.vue";

export default [
  {
    // ข่าวประชาสัมพันธ์
    path: "/news",
    name: "news",
    component: News,
  },
  {
    // รายละเอียด ข่าวประชาสัมพันธ์
    path: "/news/:id",
    name: "news-detail",
    component: NewsDetail,
  },
  {
    // ภาพกิจกรรม
    path: "/gallery",
    name: "gallery",
    component: Gallery,
  },
  {
    // รายละเอียด ภาพกิจกรรม
    path: "/gallery/:id",
    name: "gallery-detail",
    component: GalleryDetail,
  },
  {
    // ปฏิทินกิจกรรม
    path: "/calendar",
    name: "calendar",
    component: Calendar,
  },
  {
    // งานประชุมและสัมมนาวิชาการ
    path: "/conference-seminar",
    name: "conference-seminar",
    component: ConferenceSeminar,
  },
  {
    // รายละเอียด งานประชุมและสัมมนาวิชาการ
    path: "/conference-seminar/:id",
    name: "conference-seminar-detail",
    component: ConferenceSeminarDetail,
  },
  {
    // สรุปข่าวภัยพิบัติ
    path: "/news-disaster",
    name: "news-disaster",
    component: NewsDisaster,
  },
  {
    // รายละเอียด สรุปข่าวภัยพิบัติ
    path: "/news-disaster/:id",
    name: "news-disaster-detail",
    component: NewsDisasterDetail,
  },
  {
    // รายละเอียด สรุปข่าวภัยพิบัติ
    path: "/news-disaster/:id",
    name: "news-disaster-detail",
    component: StaticArticle,
  },
];
