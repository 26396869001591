<template>
  <div>
    <div v-if="$store.state.layouts.articleHeaderStyle === 'clean'">
      <!-- <p style="font-size: 30px">
        {{ $store.state.bannerText }}
      </p> -->
    </div>
    <div v-else>
      <p style="font-size: 30px">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p>
      <div class="d-flex pb-8">
        <v-btn class="bg-blue-lighten white--text mr-4" rounded small>
          <v-icon small class="mr-2">
            mdi-book-open-page-variant
          </v-icon>
          ข่าวประชาสัมพันธ์
        </v-btn>
        <p class="d-flex justify-center">
          <v-icon small class="mr-2">
            mdi-clock
          </v-icon>
          21 ตุลาคม 2563
        </p>
      </div>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
