<template>
  <div>
    <banner />
    <div class="c-main-content pt-10 pb-10">
      <v-container max-wdith="1200px">
        <v-row>
          <v-col cols="12" style="//border: 1px solid red">
            <v-card
              class="mx-auto mb-8"
              v-for="(item, index) in $store.state.pageContent"
              :key="index"
            >
              <v-container class="pl-8 pt-5 pr-8 pb-5">
                <v-row>
                  <v-col cols="12" :sm="12" :md="12" :lg="5">
                    <v-img
                      class="white--text align-end"
                      width="100%"
                      :src="item.thumbnail"
                      contain
                    ></v-img>
                  </v-col>
                  <v-col cols="12" :sm="12" :md="12" :lg="7">
                    <v-btn class="bg-blue-lighten white--text" rounded small>
                      <v-icon small class="mr-2">
                        mdi-book-open-page-variant
                      </v-icon>
                      {{ $store.state.bannerText }}
                    </v-btn>
                    <v-btn
                      class="ml-2 bg-blue-lighten white--text"
                      rounded
                      small
                    >
                      <v-icon small class="mr-2">
                        mdi-clock
                      </v-icon>
                      <span v-text="item.date_thai"></span>
                    </v-btn>

                    <p
                      style="font-size: 24px"
                      class="pt-4 text-primary font-weight-bold cursor-pointer"
                      v-text="item.title"
                    ></p>
                    <div class="text-right">
                      <v-btn
                        class="bg-primary-red white--text"
                        @click="
                          $router.push({
                            path: `/conference-seminar/${item.id}`,
                          })
                        "
                        tile
                      >
                        ดูเพิ่มเติม <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Banner from "../../layouts/Banner";

export default {
  name: "ConferenceSeminar",
  components: {
    Banner,
  },
  created() {
    this.$store.dispatch("pageLoading", true);
  },
  mounted() {
    this.$axios
      .get(`https://research.tndr-tdpf.info/api/fetch/conference-seminar`)
      .then((res) => {
        const data = res.data;
        let newData = [];

        data.forEach((item) => {
          item.title = item.conference_title;
          item.thumbnail = item.conference_thumbnail;
          item.detail = item.conference_detail;
          delete item.news_title;
          delete item.news_thumbnail;
          delete item.news_detail;

          newData.push(item);
        });

        // console.log(newData);
        this.$store
          .dispatch("setPageContent", {
            bannerText: "งานประชุมและสัมมนาวิชาการ",
            pageContent: newData,
          })
          .then(() => {
            this.$store.dispatch("pageLoading", false);
          });
      });
  },
};
</script>

<style scoped>
.c-main-content {
  background-color: #f5f5f5;
  min-height: 600px;
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 1200px;
  }
}
</style>
