<template>
  <v-container class="pa-0" fluid>
    <v-row v-if="$store.state.sectionFooter">
      <v-col style="background-color: #F5F5F5">
        <div style="padding: 0 0 60px 0">
          <div>
            <div style="padding: 60px 0 60px 0">
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="pa-0">
                  <v-card
                    class="mx-auto bg-blue-lighten white--text"
                    height="350"
                    style="padding: 40px 20px 40px 20px"
                  >
                    <div
                      class="d-flex flex-column justify-center align-center text-left"
                    >
                      <v-icon x-large>
                        mdi-office-building-marker-outline
                      </v-icon>
                      <v-card-title>
                        <p>ช่องทางการติดต่อ</p>
                      </v-card-title>
                      <v-card-subtitle>
                        <div style="height: 80px">
                          <p class="white--text">
                            <v-icon>mdi-facebook</v-icon>
                            <span v-text="facebook"></span>
                          </p>
                          <p class="white--text">
                            <v-icon>mdi-phone</v-icon>
                            <span v-text="phoneNumber"></span>
                          </p>
                          <p class="white--text">
                            <v-icon>mdi-email</v-icon>
                            <span v-text="email"></span>
                          </p>
                        </div>
                      </v-card-subtitle>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="pa-0">
                  <v-card
                    class="mx-auto bg-blue-lighten"
                    height="350"
                    style="padding: 40px 20px 40px 20px"
                  >
                    <div
                      class="d-flex flex-column justify-center align-center text-center"
                    >
                      <v-icon x-large>
                        mdi-office-building-marker-outline
                      </v-icon>
                      <v-card-title>
                        <p class="white--text">ที่อยู่สำนักงาน</p>
                      </v-card-title>
                      <v-card-subtitle>
                        <div style="height: 80px">
                          <p class="white--text" v-html="address"></p>
                        </div>
                      </v-card-subtitle>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="pa-0">
                  <v-card height="350px">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15499.878941369068!2d100.5057854!3d13.780698!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29bdff30bdc81%3A0x9fd6e7c90c2470d4!2sNavamindradhiraj%20University!5e0!3m2!1sen!2sth!4v1725263521965!5m2!1sen!2sth"
                      width="100%"
                      height="350"
                      frameborder="0"
                      style="border:0;"
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-footer absolute class="font-weight-medium bg-primary white--text">
      <v-col class="text-center" cols="12">
        <strong
          >{{ new Date().getFullYear() }} &copy; Thai Network for Disaster
          Resilience</strong
        >
      </v-col>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      facebook: "",
      phoneNumber: "",
      email: "",
      address: "",
    };
  },
  mounted() {
    this.$axios
      .get("https://research.tndr-tdpf.info/api/fetch/contact-us")
      .then((res) => {
        const data = res.data;

        this.facebook = data.facebook;
        this.phoneNumber = data.phone_number;
        this.email = data.email;
        this.address = data.address;
      });
  },
};
</script>
