import { render, staticRenderFns } from "./NewsDisaster.vue?vue&type=template&id=246deab6&scoped=true"
import script from "./NewsDisaster.vue?vue&type=script&lang=js"
export * from "./NewsDisaster.vue?vue&type=script&lang=js"
import style0 from "./NewsDisaster.vue?vue&type=style&index=0&id=246deab6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246deab6",
  null
  
)

export default component.exports