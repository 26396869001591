<template>
  <div class="c-container">
    <!-- <div :sticky="true" class="c-v-banner"></div> -->
    <!-- <img
      src="https://kpi.ac.th/assets/img/news.jpg"
      alt="Avatar"
      class="image"
      width="100%"
      height="auto"
    /> -->
    <div class="overlay">
      <div
        class="d-flex flex-column justify-center align-center"
        style="height: 200px"
      >
        <h3 style="font-size: 40px" class="font-weight-light">
          {{ syncBannerText }}
        </h3>

        <span>
          <router-link :to="{ name: 'home' }">
            หน้าแรก
          </router-link>
          | {{ $store.state.bannerText }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      syncBannerText: this.$store.state.bannerText,
    };
  },
  computed: {
    ...mapState(["bannerText"]),
  },
  watch: {
    bannerText(newValue) {
      // console.log(newValue);
      this.syncBannerText = newValue;
    },
  },
};
</script>

<style scoped>
/* Container needed to position the overlay. Adjust the width as needed */
.c-container {
  position: relative;
  width: 100%;
  min-height: 240px;
  max-height: 240px;
}

/* Make the image to responsive */
.image {
  display: block;
  width: 100%;
  height: 240px;
  object-fit: cover;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: absolute;
  bottom: 0;
  height: 240px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.84); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

/* When you mouse over the container, fade in the overlay title */
.c-container .overlay {
  opacity: 1;
}
</style>
