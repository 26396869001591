<template>
  <div>
    <banner />
    <v-container style="max-height: 100vh">
      <v-row>
        <v-col cols="12">
          <div class="pt-14 pb-14">
            <!-- <iframe
              src="https://calendar.google.com/calendar/embed?src=prawared.bw%40gmail.com&ctz=Asia%2FBangkok"
              style="border: 0"
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
            ></iframe> -->
            <iframe
              src="https://calendar.google.com/calendar/embed?src=tdpf.tndr%40gmail.com&ctz=Asia%2FBangkok"
              style="border: 0"
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
            ></iframe>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Banner from "../../layouts/Banner";

export default {
  data() {
    return {
      data: [
        {
          id: "test-user2e@test-email.ai",
          summary: "description ....",
          color: "#cd74e6",
          dates: [
            {
              id: "7413lef3g1hip8hvk6tbipkqrq_20200917T140000Z",
              summary: "event name",
              start: { dateTime: "2020-09-17T10:00:00-04:00" },
              end: { dateTime: "2020-09-17T11:00:00-04:00" },
            },
          ],
        },
        {
          id: "test-user2e@test-email.ai",
          summary: "description ....",
          color: "#cd74e6",
          dates: [
            {
              id: "7413lef3g1hip8hvk6tbipkqrq_20200917T140000Z",
              summary: "event name",
              start: { dateTime: "2020-09-17T10:00:00-04:00" },
              end: { dateTime: "2020-09-17T11:00:00-04:00" },
            },
          ],
        },
        {
          id: "test-user2e@test-email.ai",
          summary: "description ....",
          color: "#cd74e6",
          dates: [
            {
              id: "7413lef3g1hip8hvk6tbipkqrq_20200917T140000Z",
              summary: "event name",
              start: { dateTime: "2020-09-17T10:00:00-04:00" },
              end: { dateTime: "2020-09-17T11:00:00-04:00" },
            },
          ],
        },
        {
          id: "test-user2e@test-email.ai",
          summary: "description ....",
          color: "#cd74e6",
          dates: [
            {
              id: "7413lef3g1hip8hvk6tbipkqrq_20200917T140000Z",
              summary: "event name",
              start: { dateTime: "2020-09-17T10:00:00-04:00" },
              end: { dateTime: "2020-09-17T11:00:00-04:00" },
            },
          ],
        },
      ],
    };
  },
  components: {
    Banner,
  },
  mounted() {
    this.$store.dispatch("bannerText", "ปฏิทินกิจกรรม");
  },
};
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .container {
    max-width: 1200px;
  }
}
</style>
